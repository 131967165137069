import "antd/dist/antd.css";
import './App.css';

import {Card, Upload, message, Col, Row, Typography} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import React from 'react';

import DataService from "./services/data";
import * as zip from "@zip.js/zip.js";
import moment from "moment";

const { Title, Text } = Typography;

class Import extends React.Component {
    constructor(props) {
        super(props);

        this.data = DataService;

        this.handleFile = this.handleFile.bind(this);

        // Try loading data from hashmark
        if (window.location.hash) {
            let urlParams = new URLSearchParams(window.location.hash.substr(1));
            if (urlParams.get('f')) {
                this.data.importFromData(urlParams);

                this.props.history.push('/stats');
            }
        } else {
            // Try loading data from param
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('f')) {
                this.data.importFromData(urlParams);

                this.props.history.push('/stats');
            }
        }
    }

    handleFile(file) {
        let files = (new zip.ZipReader(new zip.BlobReader(file))).getEntries();

        files.then(data => {
            if (data[0].filename === "_chat.txt") {
                return data[0].getData(new zip.BlobWriter());
            } else {
                throw new Error("Invalid Zip structure");
            }
        }).then(blob => {
            return blob.text();
        }).then(text => {
            return this.data.importFromFile(text);
        }).then(() => {
            return this.props.history.push('/stats');
        })
        .catch((err) => {
            message.error(err);
        });

        return false; // Don't upload
    };

    render() {
        let exampleLink = "/?f=" + (this.data.getWordleID(moment()) - 118 ) + "&John=05545534444444434674445233335344034330343430000000000000000000000000000000000000000000000644230335246455365542435553433&Sam=00000000000000000000004275344435335533533434643443534243334634343654544235542553345333564644343445445344635553535753625&Rachel=05434443654343033044540363343365333443753533553474345333534636344444543444434454550304504000305356345334533544435554440&Will=44334534745243435446554465454463535353434453444443744435543645333433443355544357536434543644435454553344446552435544544&Jane=03455633644254546036543065443425434563545405554466643334444505344544645350543456005405545050443643333444536334444444650&Mick=04364544705243445263444244554555435456376060432647456453553334442435624455404457434344644355240546435543564665425733350&Samantha=04444654037066534435646346464554345533333435643460333354356344433434363365343355444576474326300445434444656555476563353&Luke=04435452544263532554645154572554447633434533546375565454435553454746544544554445435444552555747444436454436554434663443";

        return (
            <Row align="middle" justify="center" gutter={40} style={{flex: 1}}>
                <Col xs={20} md={6}>
                    <Upload
                        name="avatar"
                        showUploadList={false}
                        beforeUpload={this.handleFile}
                        className='upload-container'
                    >
                        <UploadOutlined />
                        <Title level={3}>Click or drag zip to import</Title>
                        <Text type="secondary">All chat data is processed locally</Text><br/><br/>
                        <Text type="secondary" onClick={e => { e.stopPropagation(); }}>Try our <a href={exampleLink}>example data</a></Text>
                    </Upload>
                </Col>
                <Col xs={24} md={12}>
                    <Card title="What is this?">
                        <p>Visualise your historic Wordle games that you have shared via WhatsApp. Currently only handles messages sent using the official share link.</p>
                        <p>Select "Export Chat" from your favourite WhatsApp group and upload the .zip file here. All data is processed locally and never leaves your device.</p>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Import;
