import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Import from "./Import";
import Stats from "./Stats";
import {Layout} from "antd";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.navRef = React.createRef();
    }

    render() {
        return (
            <BrowserRouter>
                <Layout className="container">
                    <Switch>
                        <Route exact path="/" component={Import}/>
                        <Route path="/stats" component={Stats}/>
                    </Switch>
                </Layout>
            </BrowserRouter>
        )
    }
}