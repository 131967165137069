import "antd/dist/antd.css";
import './App.css';

import { Row, Col, Card, Space, Table, Statistic, Timeline, Button, Empty, Typography, message } from 'antd';
import {
    TrophyTwoTone,
    SmileTwoTone,
    SlidersTwoTone,
    ProfileTwoTone,
    CloseOutlined,
    LeftOutlined,
    RightOutlined,
    MehTwoTone,
    ShareAltOutlined,
    EditOutlined
} from '@ant-design/icons';
import { Bar } from '@ant-design/plots';
import { gold, orange, grey, red } from '@ant-design/colors';

import React from 'react';

import DataService from "./services/data";

import moment from "moment";

const { Text } = Typography;

class Stats extends React.Component {
    constructor(props) {
        super(props);

        this.data = DataService;
        if (!this.data.setup) {
            this.props.history.push('/');
        }

        this.mToday =  moment();
        this.mFirstWordle =  moment('2021-6-19');

        this.dailyColumns = [
            {
                title: 'Name',
                dataIndex: 'id',
                render: (data) => this.renderNameCell(data),
                fixed: 'left'
            },
            {
                title: 'Rank',
                dataIndex: 'rank',
                defaultSortOrder: 'ascend',
                sorter: (a, b) => a.rank - b.rank,
                align: 'center'
            },
            {
                title: 'Score',
                dataIndex: 'score',
                render: this.renderScoreCell,
                sorter: (a, b) => a.score - b.score,
                align: 'center'
            }
        ];

        this.state = {
            currentDay: this.mToday,
            currentWeek: moment().isoWeekday(1),
            players: this.data.players,
            exportDump: this.data.exportData()
        }

        this.state.daily = this.data.getDailyStats(this.state.currentDay);
        this.state.weekly = this.data.getWeekStats(this.state.currentWeek);

        this.state.allTimeColumns = [
            {
                title: 'Name',
                dataIndex: 'id',
                render: (data) => this.renderNameCell(data),
                fixed: 'left'
            },
            {
                title: 'Average',
                dataIndex: 'average',
                defaultSortOrder: 'ascend',
                render: average => average.toFixed(2),
                sorter: (a, b) => a.average - b.average,
                align: 'center'
            },
            {
                title: 'Games',
                dataIndex: 'count',
                sorter: (a, b) => a.count - b.count,
                align: 'center'
            }
        ];

        this.state.scoresGraphConfig = {
            data: this.data.getAllTimeScores(),
            xField: 'count',
            yField: 'score',
            height: 200,
            meta: {
                "count": {
                    min: 0
                }
            }
        };
    }

    setCurrentDay(date) {
        let data = this.data.getDailyStats(date);
        if (!data) return;

        this.setState({
            currentDay: date,
            daily: data
        })
    }

    setPlayerName(id, replacement) {
        this.data.setReplacementName(id, replacement);

        // Update state
        this.setState({
            players: this.data.players
        });
    }

    setCurrentWeek(week) {
        let data = this.data.getWeekStats(week);
        if (!data) return;

        this.setState({
            currentWeek: week,
            weekly: data
        })
    }

    generateWeeklyColumns(data) {
        let columns = [
            {
                title: 'Name',
                dataIndex: 'id',
                render: (data) => this.renderNameCell(data),
                fixed: 'left'
            },
            {
                title: 'Rank',
                dataIndex: 'rank',
                defaultSortOrder: 'ascend',
                sorter: (a, b) => a.rank - b.rank,
                align: 'center'
            }
        ];

        for(let i = 0; i < data.games.length; i ++) {
            let game = data.games[i];
            columns.push({
                title: '#' + game,
                dataIndex: game,
                render: this.renderScoreCell,
                sorter: (a, b) => a[game] - b[game],
                align: 'center'
            });
        }

        columns.push({
            title: 'Total',
            dataIndex: 'total',
            sorter: (a, b) => a.total - b.total,
            align: 'center'
        });

        columns.push({
            title: 'Average',
            dataIndex: 'average',
            sorter: (a, b) => a.average - b.average,
            align: 'center'
        });

        return columns;
    }

    generateDailyColumns() {
        let columns = [];

        for(let i = 0; i < this.state.daily.players.length; i ++) {
            let player = this.state.daily.players[i];
            columns.push({
                title: this.renderNameCell(player.id),
                dataIndex: i,
                render: this.renderScoreCell,
                align: 'center'
            });
        }

        return columns;
    }

    generateDailyData() {
        let data = [{}];

        for(let i = 0; i < this.state.daily.players.length; i ++) {
            let player = this.state.daily.players[i];
            data[0][i] = player.score;
        }

        return data;
    }

    renderNameCell(id) {
        return this.data.getPlayerName(id);
    }

    renderScoreCell(score) {
        if (score === -1) {
            return {
                props: {
                    style: { background: red[2] }
                },
                children: <CloseOutlined style={{color: '#FFF'}} />
            }
        } else if (!score) {
            return {
                props: {
                    style: { background: '#eee' }
                },
                children: null
            }
        } else {
            return score
        }
    }

    share(msg) {
        this.data.getShareLink().then(link => {
            navigator.share({
                text: msg,
                url: link
            }).catch(function (error) {
                navigator.clipboard.writeText(msg).then(function () {
                    message.info("Copied to clipboard");
                }, function (err) {
                    message.error("Could not copy text to clipboard");
                });
            });
        });
    }

    shareDailyStats() {
        let message = `✨ Wordle #${this.data.getWordleID(this.state.currentDay)} ✨

${this.renderRanks(this.state.daily.players, true)}`;

        let blank = '⬜',
            filled = '🟩';

        for (let i = 1; i <= 6; i++) {
            let count = this.state.daily.players.filter((obj) => obj.score === i).length;
            message += `\n${i}: ${filled.repeat(count)}${blank.repeat(this.state.daily.completed - count)}`;
        }

        let count = this.state.daily.players.filter((obj) => obj.score === -1).length;
        message += `\nX: ${filled.repeat(count)}${blank.repeat(this.state.daily.completed - count)}`;

        this.share(message);
    }

    shareWeeklyStats() {
        let message = `✨ Week ${this.state.weekly.weekID} ✨

${this.renderRanks(this.state.weekly.players, true)}`;

        this.state.weekly.players.forEach(player => {
            message += `\n${this.data.getPlayerName(player.id)} ${player.total} (${player.average})`
        });

        this.share(message);
    }

    renderRanks(data, text = false) {
        let cols = [],
            output = '',
            colours = [gold.primary, grey[1], orange[7]],
            medals = ['🥇', '🥈', '🥉']

        let rank = 1;

        while (rank <= 3) {
            let winners = [];
            for (let k in data) {
                let p = data[k];
                if (p.rank === rank) {
                    winners.push(p);
                }
            }

            if (winners.length > 1) {
                let names = winners.reduce((a, b) => {
                    return (a.hasOwnProperty('id') ? this.data.getPlayerName(a.id) : a) + ", " + this.data.getPlayerName(b.id)
                });

                if (text) {
                    output += `${medals[rank - 1]} ${names}\n`;
                } else {
                    cols.push(<Col>
                        <Statistic title={moment(rank, 'DD').format('Do')} value={names}
                                   prefix={<TrophyTwoTone twoToneColor={colours[rank - 1]}/>}/>
                    </Col>)
                }
                rank += winners.length - 1;
            } else if (winners.length === 1) {
                if (text) {
                    output += `${medals[rank - 1]} ${this.data.getPlayerName(winners[0].id)}\n`;
                } else {
                    cols.push(<Col>
                        <Statistic title={moment(rank, 'DD').format('Do')} value={this.data.getPlayerName(winners[0].id)}
                                   prefix={<TrophyTwoTone twoToneColor={colours[rank - 1]}/>}/>
                    </Col>)
                }
            }

            rank++;
        }

        if (text) return output;
        return (
            <Row gutter={30}>
                {cols}
            </Row>
        )
    }

    render() {
        return (
            <Row gutter={16}>
                <Col md={16} xs={24}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <Card title={
                                <Space size="middle">
                                    <Button type="text" onClick={() => { this.setCurrentDay(moment(this.state.currentDay).subtract(1, 'day')) }}>
                                        <LeftOutlined />
                                    </Button>
                                    { this.state.currentDay.calendar(null, {
                                        sameDay: '[Today]',
                                        nextDay: '[Tomorrow]',
                                        nextWeek: 'dddd',
                                        lastDay: '[Yesterday]',
                                        lastWeek: 'dddd',
                                        sameElse: 'DD/MM/YYYY'
                                    }) }
                                    <Button type="text" onClick={() => { this.setCurrentDay(moment(this.state.currentDay).add(1, 'day')) }}>
                                        <RightOutlined />
                                    </Button>
                                </Space>
                            }
                              extra={
                                  <div>
                                      Wordle #{ this.data.getWordleID(this.state.currentDay) }
                                      <Button type="text" onClick={() => { this.shareDailyStats() }}>
                                          <ShareAltOutlined />
                                      </Button>
                                  </div>
                              }>
                                    {
                                        !this.state.daily.played ?
                                            <Empty
                                                image={<MehTwoTone style={{fontSize: 60}} twoToneColor={"#eee"}/>}
                                                imageStyle={{
                                                    height: 60,
                                                }}
                                                description={"Nobody played"}
                                            />
                                        :
                                            <Space direction="vertical" size={30} style={{display: 'flex'}}>
                                                {this.renderRanks(this.state.daily.players)}

                                                <Table
                                                    dataSource={this.generateDailyData()}
                                                    columns={this.generateDailyColumns()}
                                                    pagination={false}
                                                    scroll={{x: true}}
                                                />
                                            </Space>
                                    }
                        </Card>
                        <Card title={
                                <Space size="middle">
                                    <Button type="text" onClick={() => { this.setCurrentWeek(moment(this.state.weekly.dates[0]).subtract(1, 'week')) }}>
                                        <LeftOutlined />
                                    </Button>
                                    Week { this.state.weekly.weekID }
                                    <Button type="text" onClick={() => { this.setCurrentWeek(moment(this.state.weekly.dates[0]).add(1, 'week')) }}>
                                        <RightOutlined />
                                    </Button>
                                </Space>
                              }
                              extra={
                                  <div>
                                      { this.state.weekly.dates[0].format('Do MMM') } - { this.state.weekly.dates[1].format('Do MMM') }
                                      <Button type="text" onClick={() => { this.shareWeeklyStats() }}>
                                          <ShareAltOutlined />
                                      </Button>
                                  </div>
                              }
                        id={"weekly"}>
                            <Space direction="vertical" size={30} style={{display: 'flex'}}>
                                { this.renderRanks(this.state.weekly.players) }

                                <Table dataSource={this.state.weekly.players} columns={this.generateWeeklyColumns(this.state.weekly)} pagination={false}
                                       scroll={{x: true}}/>
                            </Space>
                        </Card>
                        <Card title="All time">
                            <Space direction="vertical" size={30} style={{display: 'flex'}}>
                                { this.renderRanks(this.state.players) }

                                <Table dataSource={this.state.players} columns={this.state.allTimeColumns} pagination={false}
                                       scroll={{x: true}}/>
                            </Space>
                        </Card>
                    </Space>
                </Col>
                <Col md={8} xs={24}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <Card>
                            <Space direction="vertical" size="small" style={{display: 'flex'}}>
                                <Row>
                                    <Col flex="auto">
                                        <Statistic title="Players" value={this.data.getTotalPlayers()}
                                               prefix={<SmileTwoTone/>}/>
                                    </Col>

                                    <Col flex="none">
                                        <Button size="small" icon={<EditOutlined />} onClick={() => { this.setState({showPlayerList: !this.state.showPlayerList}) }}>
                                             Edit players
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    this.state.showPlayerList ?
                                        <Space direction="vertical">
                                            {
                                                this.state.players.map((player, index) =>
                                                    <Text
                                                        editable={{
                                                            icon: <EditOutlined />,
                                                            tooltip: 'click to change player name',
                                                            onChange: data => { this.setPlayerName(index, data) },
                                                        }}>
                                                        { player.name }
                                                    </Text>
                                                )
                                            }
                                        </Space>
                                    : null
                                }

                                <Statistic title="Games" value={this.data.getNumberOfGames()} prefix={<ProfileTwoTone/>}/>
                                <Statistic title="Average" value={this.data.getAllTimeAverage()} prefix={<SlidersTwoTone/>}/>

                                <div className="ant-statistic">
                                    <div className="ant-statistic-title">Scores</div>
                                    <div className="ant-statistic-content">
                                        <Bar {...this.state.scoresGraphConfig} />
                                    </div>
                                </div>
                            </Space>
                        </Card>
                        <Card>
                            <Timeline>
                                {
                                    this.data.timeline.map(event => {
                                        return <Timeline.Item>{ this.data.getPlayerName(event.id) } { this.data.timelineEvents[event.event] } #{ event.game }</Timeline.Item>
                                    })
                                }
                            </Timeline>
                        </Card>
                    </Space>
                </Col>
            </Row>
        );
    }
}

export default Stats;
