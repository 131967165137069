import moment from "moment";

class DataService {
    firstGame;
    lastGame;
    shareLink;

    constructor(data) {
        this.setup = false;

        this.mToday =  moment();
        this.mFirstWordle =  moment().set({'year': 2021, 'month': 5, 'date': 19});
    }

    importFromFile(data) {
        return new Promise((resolve, reject) => {
            this.players = [];
            this.shareLink = null;

            const regex = /^^\[\d{1,2}\/\d{1,2}\/\d{2,4}, \d{1,2}:\d{1,2}:\d{1,2}\] (?<name>[^:\n]+):[^\]]*Wordle (?<game>[\d,]+) (?<score>\d|X)\/6(\n\n(?<guesses>[⬜🟨🟩\n]+))?/gm;

            let match;

            while ((match = regex.exec(data)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (match.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                let name = match.groups.name;
                let game = parseInt(match.groups.game.replace(',',''));
                let score = parseInt(match.groups.score);

                this.setPlayerGame(name, game, score);
            }

            // No games played
            if (!this.players.length) {
                return reject('No games were found in chat log');
            }

            this.replaceNames();

            this.generateStats();

            resolve();
        });
    }

    importFromData(data) {
        this.players = [];
        this.lastGame = null;
        this.shareLink = null;

        if (data.get('f')) {
            this.firstGame = parseInt(data.get('f'));
        } else {
            alert('error');
        }

        data.forEach((value, key) => {
            if (key === 'f') return;

            let name = key;

            let games = value.split('');
            for(let i in games) {
                let game = parseInt(i) + this.firstGame;
                let score = parseInt(games[i]);

                switch(score) {
                    case 0: score = null; break;
                    case 7: score = -1; break;
                    default: // Nothing else
                }

                this.setPlayerGame(name, game, score);
            }
        });

        this.replaceNames();

        this.generateStats();
    }

    replaceNames() {
        let names = JSON.parse(window.localStorage.getItem("player_names"));
        if (!names || !names.length) return;

        this.players.forEach(player => {
            let match = names.find(n => n.o === player.name);
            if (match) {
                player.name = match.r;
            }
        });
    }

    setReplacementName(id, replacement) {
        let names = JSON.parse(window.localStorage.getItem("player_names"));
        if (!names) {
            names = [];
        }

        let match = names.find(n => n.o === this.players[id].name_original);

        if (match) {
            let index = names.indexOf(match);
            names[index].r = replacement;
        } else {
            names.push({
                o: this.players[id].name_original,
                r: replacement
            });
        }

        this.players[id].name = replacement;

        window.localStorage.setItem("player_names", JSON.stringify(names));
    }


    generateStats() {
        this.games = [];

        this.timeline = [];
        this.timelineEvents = [
            "got their first 1er!",
            "got their first 2er!"
        ];

        this.allTimeAverage = 0;
        this.allTimeScores = [0,0,0,0,0,0,0];

        let allTimeCount = 0,
            allTimeTotal = 0;

        this.players.forEach((player, playerID) => {
            player.total = 0;
            player.count = 0;

            for (let gameID in player.games) {
                let score = player.games[gameID];
                if (!score) continue; // Skip if user didn't play game

                // Store games
                let gameIndex = this.games.findIndex(game => game.id === gameID);

                if (gameIndex === -1) {
                    this.games.push({
                        id: gameID,
                        completed: 0,
                        failed: 0,
                        total: 0,
                        players: 0
                    });
                    gameIndex = this.games.length - 1;
                }

                player.count++;

                this.games[gameIndex].players++;

                // Only include successful games in all time average
                if (score >= 1 && score <= 6) {
                    allTimeCount++;
                    allTimeTotal += score;
                    player.total += score;
                    this.games[gameIndex].completed++;
                    this.games[gameIndex].total += score;

                    this.allTimeScores[score - 1]++;
                } else {
                    player.total += 7;
                    this.games[gameIndex].failed++;

                    this.allTimeScores[6]++;
                }

                // Add to timeline
                if (score === 1 && this.timeline.findIndex(t => t.id === playerID && t.event === 0) === -1) {
                    this.timeline.push({
                        id: playerID,
                        event: 0,
                        game: gameID
                    });
                }
                if (score === 2 && this.timeline.findIndex(t => t.id === playerID && t.event === 1) === -1) {
                    this.timeline.push({
                        id: playerID,
                        event: 1,
                        game: gameID
                    });
                }
            }

            player.average = player.total / player.count;
        });

        if (allTimeCount && allTimeTotal) {
            this.allTimeAverage = (allTimeTotal / allTimeCount).toFixed(1);
        }

        // Sort games by id
        this.games.sort((a, b) => a.id - b.id);

        let gamesSortDifficulty = this.games.slice().sort((a, b) => a.total / a.players - b.total / b.players);
        this.easiestGame = gamesSortDifficulty.pop();
        this.hardestGame = gamesSortDifficulty.shift();
        console.log(this.easiestGame, this.hardestGame);

        // Sort timeline
        this.timeline.sort((a, b) => a.game - b.game);

        // Calculate ranks
        this.players.sort(function(a, b){
            return a.average - b.average;
        });

        let rank = 1;
        for (let i = 0; i < this.players.length; i++) {
            if (i > 0 && this.players[i].total === this.players[i - 1].total) {
                this.players[i].rank = this.players[i - 1].rank;
            } else {
                this.players[i].rank = rank;
            }

            rank++;
        }

        this.setup = true;
    }

    getShareLink() {
        if (this.shareLink) {
            return new Promise((resolve) => {
                resolve(this.shareLink);
            });
        }

        const url = `${window.location.protocol}//${window.location.host}/#${new URLSearchParams(this.exportData()).toString()}`;

        console.log(url);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "dynamicLinkInfo": {
                    "domainUriPrefix": "https://wstats.page.link",
                    "link": url
                }
            })
        };

        return fetch('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyD_xD5kpmaQBAWzbmu-XU9MaRdnSlN46lQ', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.shareLink = data.shortLink;
                return this.shareLink;
            });
    }

    exportData() {
        let data = {f: this.firstGame};
        for(let key in this.players) {
            let player = this.players[key];
            data[player.name] = [];

            for(let i = this.firstGame; i <= this.lastGame; i++) {
                if (!player.games[i]) {
                    data[player.name].push(0);
                } else if (player.games[i] === -1) {
                    data[player.name].push(7);
                } else {
                    data[player.name].push(player.games[i]);
                }
            }

            data[player.name] = data[player.name].join('');
        }

        return data;
    }

    sortScores(a, b) {
        let aScore = a.score,
            bScore = b.score;
        if (!aScore) {
            aScore = 999;
        }
        if (!bScore) {
            bScore = 999;
        }
        if (aScore === -1) {
            aScore = 99;
        }
        if (bScore === -1) {
            bScore = 99;
        }

        // Sort if both are actual scores
        return aScore > bScore ? 1 : aScore < bScore ? -1 : 0;
    }

    getWordleID(date) {
        return date.diff(this.mFirstWordle, 'days');
    }

    getWordleDate(id) {
        return moment(this.mFirstWordle).add(id, 'days');
    }

    getTotalGames() {
        return this.games.length;
    }

    getFirstGame() {
        return this.games[0];
    }

    getLastGame() {
        return this.games[this.games.length - 1];
    }

    getPlayerID(name) {
        let player = this.players.find(player => player.name === name);

        if (player) {
            return player.id;
        } else {
            return -1;
        }
    }

    setPlayerGame(name, game, score, guesses) {
        let playerID = this.getPlayerID(name);
        if (playerID === -1) {
            playerID = this.players.length;
            this.players.push({
                id: playerID,
                name: name,
                name_original: name,
                games: []
            });
        }

        if (score === -1 || (typeof score !== 'undefined' && isNaN(score))) {
            this.players[playerID].games[game] = -1;   // Player failed
        } else {
            this.players[playerID].games[game] = score;

            if (score) {
                this.players[playerID].total += score;
                this.players[playerID].count++;
            }
        }

        // Keep track of what games have been player
        if (!this.firstGame || this.firstGame > game) {
            this.firstGame = game;
        }

        if (!this.lastGame || this.lastGame < game) {
            this.lastGame = game;
        }

        if (guesses) {
            // this.players[playerID].games[game].guesses = guesses;
            // this.players[playerID].games[game].greens = guesses.match(/⬜/gm).length;
            // this.players[playerID].games[game].yellows = guesses.match(/⬜/gm).length;
            // this.players[playerID].games[game].greys = guesses.match(/⬜/gm).length;
        }
    }

    getTotalPlayers() {
        return this.players.length;
    }

    getNumberOfGames() {
        return this.games.length;
    }

    getAllTimeAverage() {
        return this.allTimeAverage;
    }

    getAllTimeScores() {
        let data =  this.allTimeScores.map((count, score) => {
            return {
                score: score !== 6 ? '' + (score + 1) : 'x',
                count: count
            }
        }).filter(val => val);
        return data;
    }

    getPlayerName(id) {
        let player = this.players.find(p => p.id === id);
        if (player) {
            return player.name;
        } else {
            console.error(id, this.players);
        }
    }

    getWeekStats(date) {
        let start = moment(date).day(1),
            end = moment(date).day(7);

        // if (end > moment()) {
        //     end = moment();
        // }

        let dateOfFirstGame = moment(this.mFirstWordle).add(this.getFirstGame(), 'days');

        if (end.isBefore(dateOfFirstGame, 'day')) return;

        let stats = {
            games: [],
            weekID: date.diff(dateOfFirstGame, 'weeks') + 1,
            dates: [start, end],
            players: []
        }

        // Get this weeks game list
        for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
            stats.games.push(this.getWordleID(m));
        }

        this.players.forEach((player) => {
            let data = {
                id: player.id,
                rank: 0,
                total: 0
            };

            for(let i = 0; i < stats.games.length; i++) {
                let game = stats.games[i];

                if (this.getWordleDate(game).isAfter(moment(), 'days')) continue;

                data[game] = player.games[game];

                if (data[game] > 0) {
                    data['total'] += data[game];
                } else {
                    data['total'] += 7; // Penalised for not playing or failing
                }
            }

            data['average'] = (data['total'] / stats.games.length).toFixed(1);

            stats.players.push(data);
        });

        // Calculate ranks
        stats.players.sort(function(a, b){
            return a.total - b.total;
        });

        let rank = 1;
        for (let i = 0; i < stats.players.length; i++) {
            if (i > 0 && stats.players[i].total === stats.players[i - 1].total) {
                stats.players[i].rank = stats.players[i - 1].rank;
            } else {
                stats.players[i].rank = rank;
            }

            rank++;
        }

        return stats;
    }

    getDailyStats(date) {
        let game = this.getWordleID(date);

        if (date.isAfter(moment(), 'day')) return;

        let stats = {
            players: [],
            average: 0,
            completed: 0,
            played: false
        }

        let total = 0;

        if (!this.players.length) return stats;

        this.players.forEach((player) => {
            let score = player.games[game],
                data = {
                    id: player.id,
                    rank: 0,
                    score: score
            };

            if (score) {
                total += score;
                stats.played = true;
                stats.completed++;
            }

            stats.players.push(data);
        });

        // Calculate average
        stats.average = (total / stats.players.length).toFixed(1);

        // Calculate ranks
        stats.players.sort(this.sortScores);

        let rank = 1;
        for (let i = 0; i < stats.players.length; i++) {
            if (i > 0 && stats.players[i].score === stats.players[i - 1].score) {
                stats.players[i].rank = stats.players[i - 1].rank;
            } else {
                stats.players[i].rank = rank;
            }

            rank++;
        }

        return stats;
    }
}

export default new DataService();